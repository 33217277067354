import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
  
    new Menu (8, 'Agences', '/all_user', null, 'supervisor_account', null, false, 0), 
    new Menu (9,"Dossiers encours d'exécution", '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (10, 'Dossiers clôturés', '/commandeTraite', null, 'view_module', null, false, 0), 


    
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
  
    new Menu (8, 'Agences', '/all_user', null, 'supervisor_account', null, false, 0), 
    new Menu (9,"Dossiers encours d'exécution", '/commandeAttente', null, 'view_module', null, false, 0), 
    new Menu (10, 'Dossiers clôturés', '/commandeTraite', null, 'view_module', null, false, 0), 


   
]