import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandeService } from '../services/commande.service';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-valider-package',
  templateUrl: './valider-package.component.html',
  styleUrls: ['./valider-package.component.scss']
})
export class ValiderPackageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ValiderPackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private produitService: ProduitService,private commandeService:CommandeService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.commandeService.validerPackage(this.data.commande_id).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Dossier Valider!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }


}
