import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { UserService } from 'src/app/services/user.service';
import { emailValidatorRegister } from 'src/app/validators/email-validator-register';
import { telValidatorRegister } from 'src/app/validators/telephone-validator-register';



@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  etatPadding: boolean = true;
  _id: any;
  quantite: any;
  zone: any;
  livraison: any;
  livraisons = 0;
  constructor( public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder, private userService: UserService, private _snackBar: SnackBarService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
 
  }

  

  registerForm = this.fb.group({
    name: ['', [Validators.required]],
    tel: ['', {
      validators: [
      Validators.required,
        Validators.minLength(9),
        Validators.pattern(/^[0-9+]{9,}$/)
    ],
      asyncValidators: [telValidatorRegister(this.userService)]}
    ],
    role: ['Distributeur'],
    zone: ['',[]],
    prenom: [''],
    coordonner: [''],
    commune: [''],
    societe: [''],
    ville: [''],
    nb_commande: ['',[]],
    email: ['', {
      validators: [
       Validators.required,
        Validators.email,
     ],
      asyncValidators: [emailValidatorRegister(this.userService, 'register')]}
   ]
  });

  onSubmit(){
    this.etatPadding = false;
    this.societe.setValue(this.userService.getUserDetails().societe);

    this.userService.AddDistributeur(this.registerForm.value).subscribe(res => {
      console.log("user",this.registerForm.value)
      this._snackBar.openSnackBar("Enregistrement Reuissie!!", "FERMER");
      this.etatPadding = true;
      this.dialogRef.close();
    });

    

    this.etatPadding = false;

  
  }
  selectZone(event: any){
    this.livraisons = event.target.value;
   
  }
  OnClose(){
    this.dialogRef.close();
  }
  getNameError() {
    if (this.name.invalid && (this.name.dirty || this.name.touched)) {
      if (this.name.errors.required) {
        return 'Le nom est requis!';
      }
    }
  }

  getNameSuccess() {
    if (this.name.valid) {
      return true;
    }
  }

  getTelError() {
    if (this.tel.invalid && (this.tel.dirty || this.tel.touched)) {
      if (this.tel.errors.required) {
        return 'Le numero est requis!';
      }else if(this.tel.errors.telExist){
        return 'Ce numero de telephone existe dejat!';
      }else if(this.tel.errors.pattern){
        return 'Numero telephone incorect!!';
      }else if(this.tel.errors.codeErr){
        return 'Code telephone incorect!!';
      }
    }
  }

  getTelSuccess() {
    if (this.tel.valid) {
      return true;
    }
  }

  getEmailError() {
    if (this.email.invalid && (this.email.dirty || this.email.touched)) {
      if (this.email.errors.required) {
        return 'Le email est requis!';
      }else if(this.email.errors.emailExist){
        return 'Ce email existe dejat!';
      }else if(this.email.errors.email){
        return 'Email incorect!!';
      }
    }
  }
  getRoleError() {
    if (this.role.invalid && (this.role.dirty || this.role.touched)) {
      if (this.role.errors.required) {
        return 'le role est requis!';
      }
    }
  }
  getRoleSuccess() {
    if (this.role.valid) {
      return true;
    }
  }
  getEmailSuccess() {
    if (this.email.valid) {
      return true;
    }
  }
  
  get societe(){
    return this.registerForm.get('societe');
  }
  get role(){
    return this.registerForm.get('role');
  }
  get name(){
    return this.registerForm.get('name');
  }

  get email(){
    return this.registerForm.get('email');
  }
  get coordonner(){
    return this.registerForm.get('coordonner');
  }
  

  get tel(){
    return this.registerForm.get('tel');
  }
  get prenom(){
    return this.registerForm.get('prenom');
  }
  get commune(){
    return this.registerForm.get('commune');
  }
  get ville(){
    return this.registerForm.get('ville');
  }
}
